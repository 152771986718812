.education {
    width: 100vw;
}

@media only screen and (min-width: 768px) {
    .education {
        width: 700px;
    }
}

@media only screen and (min-width: 1000px) {
    .education {
        width: 900px;
    }
}