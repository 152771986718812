
.nav {
    width: 100%;
    padding-top: 50px;
}

.nav_profile_photo {
    width: 50px;
    height: 50px;
}

.nav_center_profile {
    display: block;
}

@media only screen and (min-width: 1225px) {
    .nav {
        max-width: 1225px;
    }
    .nav_center_profile {
        display: block;
    }
}

.nav_header {
    font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 700;
    text-transform: uppercase;
    color: #01BAEF;
    font-size: medium;
}

.nav_secondary_header {
    color: #00A5D3 !important;
}

Button {
    width: 125px;
}