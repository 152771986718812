.App-header {
  background-color: #FDFFFC;
  /* min-height: 100vh; */
  /* min-width: 100vw; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  color: #011627;
}

.App-link {
  color: #61dafb;
}

.page {
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

body {
  background-color: #FDFFFC;
}

/* #007bff resume highlight color*/