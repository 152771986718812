.about {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.header {
    font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 700;
    text-transform: uppercase;
    color: #01BAEF;
    padding: 0px 10px;
    padding-top: 20px;
}

.secondary_header {
    color: #00A5D3 !important;
}

.subheading {
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.2rem;
    margin-bottom: 48px;
    padding: 0px 10px;
}

.profile_photo {
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
}

@media only screen and (min-width: 768px) {
  .subheading {
    font-size: 1.5rem;
  }
  .profile_photo {
      width: 150px;
      height: 150px;
      max-width: 150px;
      max-height: 150px;
  }
}

.lead {
    font-size: 1.15rem;
    font-weight: 400;
    margin-bottom: 48px;
    padding: 0px 10px;
}

.social-icons a {
  display: inline-block;
  height: 3.5rem;
  width: 3.5rem;
  background-color: #495057;
  color: #fff !important;
  border-radius: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3.5rem;
  margin-right: 1rem;
  padding: 0px 10px;
}

.social-icons a:last-child {
  margin-right: 0;
}

.social-icons a:hover {
  background-color: #BD5D38;
}