.experience {
    width: 90vw;
}

.title {
    font-size: medium;
}

/* @media only screen and (min-width: 768px) {
    .experience {
        width: 718px;
    }
}  */

/* @media only screen and (min-width: 1000px) {
    .experience {
        width: 950px;
    }
} */

@media only screen and (min-width: 1200px) {
    .experience {
        width: 1150px;
    }
}